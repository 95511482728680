<template>
  <UCard>
    <Datatable
      :headers="headers"
      :elements="transactions"
      :group-by="groups"
      no-footer
      grid-columns="lastSmallest"
    >
      <template #security="{ element }">
        {{ element.security.fullName }}
      </template>
      <template #shareholder="{ element }">
        {{ element.shareholder.fullName }}
      </template>
      <template #plan="{ element }">
        {{ element.plan?.name }}
      </template>
      <template #is_reserve="{ element }">
        {{ element.is_reserve ? 'Yes' : 'No' }}
      </template>
      <template #operation="{ element }">
        {{ element.operation?.name }}
      </template>
      <template #actions="{ element }">
        <SettingsIconEdit @click="itemEdit = element" />
        <SettingsIconDelete @click="itemDelete = element" />
      </template>
      <template #append>
        <SettingsTransactionNew :headers="headers" />
      </template>
    </Datatable>
    <LazySettingsTransactionEdit v-model="itemEdit" />
    <LazySettingsTransactionDelete v-model="itemDelete" />
  </UCard>
</template>

<script setup lang="ts">
import type { Transaction } from '~/database'

const { $store } = useNuxtApp()
const transactions = computed(() => $store.organization?.transactions as Transaction[] | undefined)
const headers: TableHeader<Transaction>[] = [
  { key: 'date', text: 'Date', format: 'longdate' },
  { key: 'type', text: 'Type' },
  { key: 'security', text: 'Security' },
  { key: 'shareholder', text: 'Shareholder' },
  { key: 'plan', text: 'Plan', align: 'left' },
  { key: 'quantity', text: 'Quantity', format: 'integer', align: 'center' },
  { key: 'unit_price', text: 'Unit Price', format: 'accounting2', align: 'center' },
  { key: 'value', text: 'Amount', format: 'amount', align: 'center' },
  { key: 'is_reserve', text: 'Reserve ?', align: 'center' },
  { key: 'operation', text: 'Operation', align: 'center' },
  { key: 'actions', text: 'Actions', align: 'center' }
]

const groups: TableGroup<Transaction>[] = [{ key: 'id'}]
const itemEdit = ref<Transaction>()
const itemDelete = ref<Transaction>()
</script>
