<template>
  <SettingsFoundationNew
    v-model="state"
    :headers="headers"
    :validate="validate"
    :create-callback="create"
    table="transactions"
  >
    <template #date="{ errors }">
      <UFormGroup
        size="2xs"
        :error="errors.date"
        :help="filters.formatLongDate(state.date)"
      >
        <UInput
          v-model="state.date"
          type="date"
        />
      </UFormGroup>
    </template>
    <template #type="{ errors }">
      <UFormGroup
        size="2xs"
        :error="errors.type"
        :help="typeMessage"
      >
        <USelect
          v-model="state.type"
          :options="types"
        />
      </UFormGroup>
    </template>
    <template #security="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.security"
          :help="securityMessage"
        >
          <USelect
            v-model="state.security_id"
            :options="$store.organization?.securities"
            option-attribute="fullName"
            value-attribute="id"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'conversion'"
          size="2xs"
          :error="errors.converted_security"
          :help="convertedSecurityMessage"
        >
          <USelect
            v-model="state.converted_secid"
            :options="$store.organization?.securities"
            option-attribute="fullName"
            value-attribute="id"
          />
        </UFormGroup>
      </div>
    </template>
    <template #shareholder="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.shareholder"
          :help="shareholderMessage"
        >
          <USelect
            v-model="state.shareholder_id"
            :options="$store.organization?.shareholders"
            option-attribute="name"
            value-attribute="id"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'transfer'"
          size="2xs"
          :error="errors.counterpart"
          :help="counterpartMessage"
        >
          <USelect
            v-model="state.counterpart_shid"
            :options="$store.organization?.shareholders"
            option-attribute="name"
            value-attribute="id"
          />
        </UFormGroup>
      </div>
    </template>
    <template #plan="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.plan"
          help="Plan"
        >
          <USelect
            v-model="state.plan_id"
            :options="plans"
            option-attribute="name"
            value-attribute="id"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'conversion' || state.type === 'transfer'"
          size="2xs"
          :error="errors.counterpartPlan"
          help="Counterpart Plan"
        >
          <USelect
            v-model="state.counterpart_plid"
            :options="counterpartPlans"
            option-attribute="name"
            value-attribute="id"
          />
        </UFormGroup>
      </div>
    </template>
    <template #quantity="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.quantity"
          :help="quantityMessage"
        >
          <UInput
            v-model="state.quantity"
            :disabled="state.type === 'dividend'"
            type="number"
            @update:model-value="onQuantityUpdate"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'conversion'"
          size="2xs"
          :error="errors.converted_quantity"
          :help="convertedQuantityMessage"
        >
          <UInput
            v-model="state.converted_quantity"
            type="number"
            @update:model-value="onConvertedQuantityUpdate"
          />
        </UFormGroup>
      </div>
    </template>
    <template #unit_price="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.unit_price"
          :help="unitPriceMessage"
        >
          <UInput
            v-model="state.unit_price"
            type="number"
            step="any"
            @update:model-value="onPriceUpdate"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'conversion'"
          size="2xs"
          :error="errors.converted_unit_price"
          help="Price per share"
        >
          <UInput
            v-model="state.converted_unit_price"
            disabled
            type="number"
            step="any"
          />
        </UFormGroup>
      </div>
    </template>
    <template #value="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.value"
          :help="amountMessage"
        >
          <UInput
            v-model="state.value"
            :label="amountMessage"
            type="number"
            step="any"
            @update:model-value="onValueUpdate"
          />
        </UFormGroup>
      </div>
    </template>
    <template #is_reserve="{ errors }">
      <div class="flex flex-col">
        <UFormGroup
          size="2xs"
          :error="errors.is_reserve"
          :help="reserveMessage"
        >
          <UCheckbox
            v-model="state.is_reserve"
            :disabled="state.type === 'grant' || state.type === 'vesting'"
          />
        </UFormGroup>
        <UFormGroup
          v-if="state.type === 'transfer' || state.type === 'conversion'"
          size="2xs"
          :error="errors.converted_isrs"
          :help="counterpartReserveMessage"
        >
          <UCheckbox
            v-model="state.counterpart_isrs"
          />
        </UFormGroup>
      </div>
    </template>
    <template #operation="{ error }">
      <UFormGroup
        size="2xs"
        :error="error"
      >
        <USelect
          v-model="state.operation_id"
          :options="operations"
          option-attribute="name"
          value-attribute="id"
        />
      </UFormGroup>
    </template>
  </SettingsFoundationNew>
</template>
<script setup lang="ts">
import type { Database } from '~/supabase/types'
import type { Transaction } from '~/database'

interface Props {
  headers: TableHeader<Transaction>[]
}
defineProps<Props>()

const types = [
  { value: 'grant', text: 'Grant' },
  { value: 'vesting', text: 'Vesting' },
  { value: 'issuance', text: 'Issuance' },
  { value: 'transfer', text: 'Transfer' },
  { value: 'dividend', text: 'Dividend' },
  { value: 'conversion', text: 'Conversion' }
]

const { $store } = useNuxtApp()

const state = reactive<{
  organization_id?: string
  date?: string
  type?: string
  security_id?: string
  shareholder_id?: string
  plan_id?: string
  quantity?: number
  unit_price?: number
  value?: number
  is_reserve?: boolean
  operation_id?: string
  counterpart_shid?: string
  counterpart_isrs?: boolean
  converted_secid?: string
  counterpart_plid?: string
  converted_quantity?: number
  converted_unit_price?: number
  converted_value?: number
}>({
  date: undefined,
  type: undefined,
  security_id: undefined,
  shareholder_id: undefined,
  plan_id: undefined,
  quantity: undefined,
  unit_price: undefined,
  value: undefined,
  is_reserve: undefined,
  operation_id: undefined,
  counterpart_shid: undefined,
  counterpart_isrs: undefined,
  converted_secid: undefined,
  counterpart_plid: undefined,
  converted_quantity: undefined,
  converted_unit_price: undefined,
  converted_value: undefined
})

function validate(state: Partial<Transaction>) {
  const errors = []
  if (!state.date) errors.push({ path: 'date', message: 'Date is required' })
  if (!state.type) errors.push({ path: 'type', message: 'Type is required' })
  if (!state.security_id) errors.push({ path: 'security', message: 'Security is required' })
  if (!state.shareholder_id) errors.push({ path: 'shareholder', message: 'Shareholder is required' })

  if (!state.quantity && state.quantity !== 0) {
    errors.push({ path: 'quantity', message: 'Quantity is required' })
  } else if (!Number.isInteger(state.quantity)) {
    errors.push({ path: 'quantity', message: 'Integer required' })
  } else if (state.type === 'conversion' && state.quantity >= 0) {
    errors.push({ path: 'quantity', message: 'Negative quantity required' })
  } else if (state.type === 'vesting' && state.quantity < 0) {
    errors.push({ path: 'quantity', message: 'Positive quantity required' })
  } 

  if (!state.unit_price && state.unit_price !== 0) {
    errors.push({ path: 'unit_price', message: 'Price is required' })
  } else if (state.type === 'dividend' && state.unit_price >= 0) {
    errors.push({ path: 'unit_price', message: 'Negative price required' })
  } else if (state.type !== 'dividend' && state.unit_price < 0) {
    errors.push({ path: 'unit_price', message: 'Positive price required' })
  } 

  if (state.type === 'transfer') { 
    if (!state.counterpart_shid) { 
      errors.push({ path: 'counterpart', message: 'Counterpart is required' }) 
    }
  }

  if (state.type === 'conversion') {
    if (!state.converted_secid) { 
      errors.push({ path: 'converted_security', message: 'Converted security is required' })
    }
    if (!state.converted_quantity && state.converted_quantity !== 0) { 
      errors.push({ path: 'converted_quantity', message: 'Quantity is required' })
    } else if (!Number.isInteger(state.converted_quantity)) {
      errors.push({ path: 'converted_quantity', message: 'Integer required' })
    } else if (state.converted_quantity <= 0) {
      errors.push({ path: 'converted_quantity', message: 'Positive quantity required' })
    }
    if (!state.converted_unit_price && state.converted_unit_price !== 0) {
      errors.push({ path: 'converted_unit_price', message: 'Price is required' })
    } else if (state.converted_unit_price < 0) {
      errors.push({ path: 'converted_unit_price', message: 'Positive price required' })
    }
  }
  return errors
}

const plans = computed(() => {
  const plans = [{ id: '', name: '[None]'}]
  const security = $store.organization?.securities.find(security => security.id === state.security_id)
  if (security) {
    plans.push(...security.plans.map(({ id, name}) => ({ id, name })))
  }
  return plans
})

const counterpartPlans = computed(() => {
  if (state.type === 'conversion') {
    const counterpartPlans = [{ id: '', name: '[None]'}]
    const security = $store.organization?.securities.find(security => security.id === state.converted_secid)
    if (security) {
      counterpartPlans.push(...security.plans.map(({ id, name }) => ({ id, name })))
    }
    return counterpartPlans
  }
  return plans.value
})

const operations = computed(() => {
  const operations = [{ id: '', name: '[None]'}]
  operations.push(...$store.organization?.operations.map(({ id, name }) => ({ id, name })) || [])
  return operations
})

function onQuantityUpdate(quantity: number | undefined) {
  if (typeof quantity === 'number') {
    if (typeof state.unit_price === 'number') {
      state.value = quantity * state.unit_price
    }
  } else {
    state.quantity = undefined
  }
}

function onPriceUpdate(unit_price: number | undefined) {
  if (typeof unit_price === 'number') {
    if (typeof state.quantity === 'number') {
      state.value = state.quantity * unit_price
    }
  } else {
    state.unit_price = undefined
  }
}

function onValueUpdate(value: number | undefined) {
  if (typeof value === 'number') {
    if (typeof state.quantity === 'number') {
      state.unit_price = state.quantity !== 0 ? value / state.quantity : undefined
    }
  } else {
    state.value = undefined
  }
}

function onConvertedQuantityUpdate(converted_quantity: number | undefined) {
  if (typeof converted_quantity === 'number' && typeof state.converted_value === 'number') {
    state.converted_unit_price = converted_quantity !== 0 ? state.converted_value / converted_quantity : undefined
  } else {
    state.converted_quantity = undefined
  }
}

watchEffect(function synchronizeConvertedValues() {
  if (state.type === 'conversion' && typeof state.value === 'number') {
    state.converted_value = -state.value
    if (typeof state.converted_quantity === 'number') {
      state.converted_unit_price = state.converted_quantity !== 0 ? state.converted_value / state.converted_quantity : undefined
    } else {
      state.converted_unit_price = undefined
    }
  } else if (state.type === 'dividend') {
    const shareholder = $store.organization?.shareholders.find(sh => sh.id === state.shareholder_id)
    const security = $store.organization?.securities.find(security => security.id === state.security_id)
    if (shareholder && security) {
      state.quantity = shareholder.outstanding(state.date, security as any)
    }
  } else {
    state.converted_value = undefined
  }
})

const typeMessage = computed(() => {
  let message = 'Transaction'
  if (state.type === 'issuance') {
    message = 'Issuance'
    if (state.quantity && state.quantity < 0) {
      message = 'Redemption'
    }
  } else if (state.type === 'transfer') {
    message = 'Acquisition'
    if (state.quantity && state.quantity < 0) {
      message = 'Sale'
    }
  } else if (state.type === 'dividend') {
    message = 'Dividend'
    if (state.quantity && state.quantity < 0) {
      message = 'Contribution'
    }
  } else if (state.type === 'conversion') {
    message = 'Conversion'
  } else if (state.type === 'grant') {
    message = 'Grant'
    if (state.quantity && state.quantity < 0) {
      message = 'Forfeit'
    }
  } else if (state.type === 'vesting') {
    message = 'Exercise'
  }
  return message
})
const securityMessage = computed(() => {
  let message = 'shares'
  const security = $store.organization?.securities.find(security => security.id === state.security_id)
  const entity = security?.entity
  if (state.type && entity?.name) {
    message = 'of ' + entity.name + ' shares'
  }
  return message
})
const convertedSecurityMessage = computed(() => {
  let message = 'into'
  const security = $store.organization?.securities.find(security => security.id === state.converted_secid)
  const entity = security?.entity
  if (state.type && entity?.name) {
    message = 'into ' + entity.name + ' shares'
  }
  return message
})
const shareholderMessage = computed(() => {
  let message = 'Shareholder'

  if (state.type) {
    if (state.type === 'issuance') {
      message = 'To: Subscriber';
      if (state.quantity && state.quantity < 0) {
        message = 'From: Beneficiary';
      }
    } else if (state.type === 'transfer') {
      message = 'By: Acquiror';
      if (state.quantity && state.quantity < 0) {
        message = 'By: Seller';
      }
    } else if (state.type === 'dividend') {
      message = 'Paid to';
      if (state.quantity && state.quantity < 0) {
        message = 'Paid by'
      }
    } else if (state.type === 'grant') {
      message = 'To: Beneficiary'
      if (state.quantity && state.quantity < 0) {
        message = 'From: Beneficiary'
      }
    } else if (state.type === 'vesting') {
      message = 'From: Holder'
    }
  }
  return message
})
const counterpartMessage = computed(() => {
  let message
  if (state.type === 'transfer') {
    message = 'From: Seller'
    if (state.quantity && state.quantity < 0) {
      message = 'To: Acquiror'
    }
  }
  return message
})
const quantityMessage = computed(() => {
  let message = 'Nb shares'
  const security = $store.organization?.securities.find(security => security.id === state.security_id)
  if (security?.name) {
    message = 'Nb ' + security.name + ' shares'
    if (state.type === 'dividend') {
      message = 'Nb ' + security.name + ' held'
    }
  }
  return message
})
const convertedQuantityMessage = computed(() => {
  let message = 'Nb shares'
  const security = $store.organization?.securities.find(security => security.id === state.converted_secid)
  if (security?.name) {
    message = 'Nb ' + security.name + ' shares'
  }
  return message
})
const unitPriceMessage = computed(() => {
  let message = 'Price per share'
  if (state.type === 'dividend' && state.unit_price && state.unit_price < 0) {
    message = 'Dividend per share'
  }
  return message
})
const amountMessage = computed(() => {
  let message = 'Amount'
  if (state.value) {
    message = 'Paid: '
    if (state.quantity && state.quantity < 0 && state.type !== 'dividend') {
      message = 'Received: '
    } else if (state.quantity && state.quantity > 0 && state.type === 'dividend') {
      message = 'Received: '
    }
    if (state.type === 'conversion') {
      message = 'Value: '
    }
    message += filters.formatAccounting(Math.abs(state.quantity! * state.unit_price!), 2)
  }
  return message
})

const reserveMessage = computed(() => {
  const message = state.is_reserve ? 'Yes' : 'No'
  return message
})

const counterpartReserveMessage = computed(() => {
  const message = state.counterpart_isrs ? 'Yes' : 'No'
  return message
})

async function create() {
  state.quantity ||= 0
  state.unit_price ||= 0
  state.value = state.quantity * state.unit_price
  if (state.type === 'dividend') {
    state.quantity = 0
  }
  const supabase = useSupabaseClient<Database>()
  const { error } = await supabase.rpc('create_transaction', state as any)
  if (error) {
    const { $toast } = useNuxtApp()
    $toast.error(error.message)
  }
}

</script>
